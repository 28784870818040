import styled from "styled-components"

const H1 = styled.h1`
  font-size: calc(0.8rem + 2.3vw);
  color: ${({ theme }) => theme.h1};
  font-family: "OFL Sorts Mill Goudy";
  text-transform: capitalize;
`

export default H1
