import styled from "@emotion/styled"
import React from "react"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
const ResizeImg = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`
const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1 },
  1920: { imtem: 3 },
}

const items = [
  <div className="item" data-value="1">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635142/Home/Slideshow/az4951_wpqknt.jpg"
      alt="Pearl Jewelry"
    />
  </div>,
  <div className="item" data-value="2">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635141/Home/Slideshow/az5332_i9vpn2.jpg"
      alt="Diamond Jewelry"
    />
  </div>,
  <div className="item" data-value="3">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635141/Home/Slideshow/az5327_rd74kd.jpg"
      alt="Gold Jewelry"
    />
  </div>,
  <div className="item" data-value="4">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635139/Home/Slideshow/az4967_yyiicy.jpg"
      alt="Pink Gold Pearl Jewelry"
    />
  </div>,
  <div className="item" data-value="5">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635139/Home/Slideshow/az4964_lnak4t.jpg"
      alt="Gold Pearl Jewelry"
    />
  </div>,
  <div className="item" data-value="6">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635139/Home/Slideshow/az5279_upcjmf.jpg"
      alt="sapphire Enamel Jewelry"
    />
  </div>,
  <div className="item" data-value="7">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635138/Home/Slideshow/az5056_mbtvks.jpg"
      alt="Gold Diamond Jewelry Set"
    />
  </div>,
  <div className="item" data-value="8">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635138/Home/Slideshow/az5276_ctkxqq.jpg"
      alt="enamel jewelry set"
    />
  </div>,
  <div className="item" data-value="9">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635137/Home/Slideshow/az5274_qlx7ak.jpg"
      alt="enamel huggies"
    />
  </div>,
  <div className="item" data-value="10">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635136/Home/Slideshow/az4949_bt7ulw.jpg"
      alt="mother of pearl jewelry"
    />
  </div>,
  <div className="item" data-value="11">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635135/Home/Slideshow/az4431_ihz5qv.jpg"
      alt="textured jewelry"
    />
  </div>,

  <div className="item" data-value="12">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635133/Home/Slideshow/az4393_r1wq6o.jpg"
      alt="textured CZ jewelry set"
    />
  </div>,
  <div className="item" data-value="13">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635132/Home/Slideshow/az4457_w95x9m.jpg"
      alt="pink gold texture jewelry"
    />
  </div>,
  <div className="item" data-value="14">
    <ResizeImg
      src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632635132/Home/Slideshow/az4948_ggpzcg.jpg"
      alt="mop jewelry"
    />
  </div>,
]
const GalleryHome = () => (
  <AliceCarousel
    mouseTracking
    autoPlay
    disableDotsControls
    autoPlayStrategy="none"
    autoPlayInterval={2000}
    animationDuration={2000}
    animationType="fadeout"
    infinite
    items={items}
    responsive={responsive}
    controlsStrategy="alternate"
  />
)
export default GalleryHome
