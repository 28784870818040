import { Link } from "gatsby"
import styled from "styled-components"

const NormalBtn = styled(Link)`
  font-family: "OFL Sorts Mill Goudy";
  font-size: calc(0.9rem + 0.8vw);
  text-transform: capitalize;
  text-decoration: none;
  border: solid 1px ${({ theme }) => theme.green};
  background: ${({ theme }) => theme.green};
  border-radius: 8px;
  letter-spacing: -0.6px;
  color: ${({ theme }) => theme.whiteBase};
  padding: 1.2vh 2.5vw;
  :hover {
    background: ${({ theme }) => theme.background};
    border: solid 1px ${({ theme }) => theme.green};
    color: ${({ theme }) => theme.green};
  }
`

export default NormalBtn
