import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import H1 from "../../../components/Typography/H1/H1"
import P from "../../../components/Typography/P/P"

const HomeHero = ({ className }) => {
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "home-header-mobile.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 690, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopImage: file(relativePath: { eq: "home-header.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 491px)`,
    },
  ]

  return (
    <BackgroundImage
      Tag={`section`}
      id={`media-test`}
      className={className}
      fluid={sources}
    >
      <StyledInnerWrapper>
        <H1Hero>Manufacturers of Fine Gold &amp; Silver Jewelry</H1Hero>
        <HeroP>
          Your trusted manufacturing partner in Asia
          <br />
          Reliably delivering quality craftsmanship around the world for over 30
          years
        </HeroP>
      </StyledInnerWrapper>
    </BackgroundImage>
  )
}

const StyledInnerWrapper = styled.div`
  padding: 15vh 15vw;
  @media (max-width: 768px) {
    padding: 7vh 0;
  }
`

const StyledHomeHero = styled(HomeHero)`
  background: no-repeat center center right;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  max-height: 70vh;
  @media (max-width: 768px) {
    min-height: 100vh;
    padding-top: 50px;
  }
`
const H1Hero = styled(H1)`
  color: ${({ theme }) => theme.h1};
  max-width: 27vw;
  text-transform: none;

  @media (max-width: 768px) {
    background-color: ${({ theme }) => theme.whiteRgba4};
    min-width: 90vw;
    text-align: center;
    font-size: calc(2.3rem + 2vw);
    margin: 0 4vw;
    padding: 1.5vh 1vw;
  }
`
const HeroP = styled(P)`
  font-size: calc(1rem + 0.7vw);
  font-family: "OFL Sorts Mill Goudy";
  max-width: 28vw;
  padding-bottom: 5vh;
  @media (max-width: 768px) {
    font-size: calc(1.3rem + 1.3vw);
    background-color: ${({ theme }) => theme.whiteRgba4};
    color: ${({ theme }) => theme.headers};
    min-width: 90vw;
    text-align: center;
    margin: 0 4vw;
    padding: 1vw;
  }
`

export default StyledHomeHero
