import styled from "styled-components"

const CenterH2 = styled.h2`
  font-size: calc(0.8rem + 2vw);
  color: ${({ theme }) => theme.h1};
  font-family: "OFL Sorts Mill Goudy";
  text-align: center;
  @media (max-width: 768px) {
    font-size: calc(1.6em + 0.5vw);
  }
`

export default CenterH2
