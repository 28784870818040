import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Flex, Box } from "reflexbox/styled-components"
import styled from "styled-components"
import H2 from "../components/Typography/H2/H2"
import P from "../components/Typography/P/P"
import GreySection from "../components/Section/GreySection"
import WrapperContainers from "../components/WrapperContainer"
import StyledHomeHero from "../containers/home/header/HomeHero"
import SpanGreen from "../components/Typography/Span/SpanGreen"
import GalleryHome from "../containers/home/header/GalleryHome"
import NormalBtn from "../components/Btn/NormalBtn"
import CenterH2 from "../components/Typography/H2/CenterH2"
import { ArrowForward } from "@styled-icons/fluentui-system-regular"
import Relative from "../components/Section/Relative"
import CenterP from "../components/Typography/P/CenterP"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Advance Manufacturers high quality jewellery manufacturer"
      description="Fine Jewelry, Wholesale Jewelry Suppliers in Thailand"
    />
    <StyledHomeHero />
    <GreySection>
      <Section>
        <Flex flexWrap="wrap" px={[3, 4, 6]} py={[2, 2, 4]}>
          <Box
            width={[1, 1, 1 / 2]}
            pt={[3, 3, 0]}
            px={[2, 2, 3]}
            order={[1, 1, 2]}
          >
            <ResponsiveCenterH2>
              About <SpanGreen>Us</SpanGreen>
            </ResponsiveCenterH2>
            <P>
              We are a family run business with over 30 years experience in the
              jewelry manufacturing industry.  We produce over 3 million pieces
              of jewelry per year. We offer all jewelry categories including
              earrings, pendants, necklaces, bracelets, rings, chains and hoops.
              We work with 8k, 9k, 10k, 14k &amp; 18k gold, 925 sterling silver
              as well as brass metal.  Stones used include precious and
              semi-precious gemstones, natural and lab-grown diamonds, cubic
              zirconia and crystals. We also offer recycled metals,
              conflict-free gemstones and biodegradable packaging.
            </P>
            <br />
            <NormalBtn to="/about-us">learn more about us</NormalBtn>
          </Box>
          <Box width={[1, 1, 1 / 2]} p={[3, 3, 4]} order={[2, 2, 1]}>
            <PadAboutImg src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632634524/Home/home-about-us_qacnqb.png" />
          </Box>
        </Flex>
      </Section>
    </GreySection>

    <Section>
      <Flex flexWrap="wrap" px={[3, 4, 6]} py={[2, 2, 4]}>
        <Box width={[1, 1, 1]} pt={[3, 3, 4]} px={[2, 2, 3]}>
          <GreyH2>
            Gold &amp; <SpanGreen>Silver Jewelry</SpanGreen>
          </GreyH2>

          <P>An array of designs to choose from!</P>

          <GalleryHome />
        </Box>
      </Flex>
    </Section>

    <GreySection>
      <Section>
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1]} pt={[3, 5, 5]}>
            <NoPadCenterH2>
              OEM <SpanGreen> Custom Design Manufacturing</SpanGreen>
            </NoPadCenterH2>
          </Box>
          <Box width={[1, 1, 1 / 3]} p={[3, 5, 5]}>
            <WrapMotivation>
              <TeamImg
                src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1630741873/Home/jewelry-design-team_ij7pal.png"
                alt="design team advance jewelry"
              />
              <GreyArrow />
            </WrapMotivation>
            <CenterP>
              Our team of experienced jewelry designers help translate your
              visions and ideas into professional sketches.
            </CenterP>
          </Box>
          <Box width={[1, 1, 1 / 3]} p={[3, 5, 5]}>
            <WrapMotivation>
              <TeamImg
                src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1633329977/Home/CAD-jewelry-300_s7kdwp.png"
                alt="CAD design  advance manucturer jewelry"
              />
              <GreyArrowFlip />
            </WrapMotivation>

            <CenterP>
              We use specialized CAD software to create  digital models of your
              jewelry.  The 3D files are used to print the master models with
              our 3D printing machine.
            </CenterP>
          </Box>
          <Box width={[1, 1, 1 / 3]} p={[3, 5, 5]}>
            <WrapMotivation>
              <TeamImg
                src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1630741567/Home/jewelry-sampling_qva1bh.png"
                alt="Sampling advance manucturer jewelry"
              />
            </WrapMotivation>
            <CenterP>
              Samples are then produced in a specialized sampling department
              where our team inspects the final sample and analyzes if any
              changes are required to optimize the design.
            </CenterP>
          </Box>
        </Flex>
      </Section>
    </GreySection>
    <Section>
      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1 / 2]} p={[3, 2, 5]} order={[2, 2, 1]}>
          <PadSecretImg
            src="https://res.cloudinary.com/denq2jckc/image/upload/v1632636157/Home/Oem-team-work_x8m9a0.png"
            alt="Advance secret success team"
          />
        </Box>
        <Box
          width={[1, 1, 1 / 2]}
          p={[3, 2, 3]}
          pr={[0, 0, 2]}
          order={[1, 1, 2]}
        >
          <ResponsiveCenterH2>Secret to our success</ResponsiveCenterH2>
          <P>
            We engage over 400 highly qualified employees with over 3 decades of
            experience in jewelry production. Honesty, fairness and loyalty are
            deeply rooted in our corporate culture and this is the driving force
            in all our dealings with our customers, suppliers and employees. A
            large part of our workforce has been with us for over 30 years. This
            is one of the most important factors contributing to our success.
            This also helps maintain the high-quality standards that we are well
            known for.
          </P>
          <PadMotivationBtn />
        </Box>
      </Flex>
    </Section>
    <Section>
      <Flex flexWrap="wrap">
        <Box width={[1, 1]} p={[3, 2, 3]} pr={[0, 0, 2]}>
          <CenterH2>
            {" "}
            Glimpses of Jewelry Production at Advance Manufacturers
          </CenterH2>
        </Box>
      </Flex>{" "}
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 3]} pr={[0, 0, 0]} py={[0, 0, 0]}>
          <Flex flexWrap="wrap">
            <Box width={[1, 1 / 1]} pb={[0, 0, 2]} pr={[0, 0, 1]}>
              <GallHome src="https://res.cloudinary.com/denq2jckc/image/upload/v1632637044/Home/Wax-Department_dcjcaf.jpg" />
            </Box>
            <Box width={[1, 1 / 1]} pt={[0, 0, 2]} pr={[0, 0, 1]}>
              <GallHome src="https://res.cloudinary.com/denq2jckc/image/upload/v1632637043/Home/Jewelry-Enamelling_yte8gj.jpg" />
            </Box>
          </Flex>
        </Box>
        <Box width={[1, 1 / 3]} px={[0, 0, 2]}>
          <GallHome src="https://res.cloudinary.com/denq2jckc/image/upload/v1632637044/Home/Jewelry-Polishing_amhe51.jpg" />
        </Box>
        <Box width={[1, 1 / 3]} pr={[0, 0, 0]} py={[0, 0, 0]}>
          <Flex flexWrap="wrap">
            <Box width={[1, 1 / 1]} pb={[0, 0, 2]} pl={[0, 0, 1]}>
              <GallHome src="https://res.cloudinary.com/denq2jckc/image/upload/v1632637690/Home/CAD-Printer-Jewelry_crlqsv.jpg" />
            </Box>
            <Box width={[1, 1 / 1]} pt={[0, 0, 2]} pl={[0, 0, 1]}>
              <GallHome src="https://res.cloudinary.com/denq2jckc/image/upload/v1633328938/Home/diamond-settings-900x600jpg_jdlgjh.jpg" />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Section>
  </Layout>
)

export default IndexPage
const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`
const ResponsiveCenterH2 = styled(H2)`
  color: ${({ theme }) => theme.green};
  @media (min-width: 768px) and (max-width: 1024px) {
    text-align: center;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    text-align: center;
    font-size: calc(2em + 0.5vw);
  }
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: calc(2em + 0.5vw);
    text-align: center;
  }
`
const WrapMotivation = styled(Relative)`
  padding: 2vh 3vw;
`
const GreyH2 = styled(H2)`
  color: ${({ theme }) => theme.text};
  @media (max-width: 768px) {
    font-size: calc(2em + 0.5vw);
  }
`
const NoPadCenterH2 = styled(CenterH2)`
  padding-top: 0;
  margin: 0;
  @media (max-width: 768px) {
    font-size: calc(2em + 0.5vw);
  }
`
const GreyArrow = styled(ArrowForward)`
  position: absolute;
  top: 0;
  right: -5vw;
  width: 4.5vw;
  color: ${({ theme }) => theme.arrow};
  @media (max-width: 768px) {
    display: none;
  }
`
const GreyArrowFlip = styled(ArrowForward)`
  position: absolute;
  bottom: -2vh;
  right: -27%;
  width: 4.5vw;
  color: ${({ theme }) => theme.arrow};
  transform: scale(1, -1);
  @media (max-width: 768px) {
    display: none;
  }
`
const PadMotivationBtn = styled.div`
  padding-top: 3vh;
`
const TeamImg = styled.img`
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`
const PadAboutImg = styled.img`
  padding-top: 12vh;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    padding-top: 25px;
  }
`
const PadSecretImg = styled.img`
  padding-top: 4vh;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    padding-top: 25px;
  }
`
const GallHome = styled.img`
  width: 100%;
  height: auto;
`
