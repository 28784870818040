import styled from "styled-components"

const P = styled.p`
  font-size: calc(0.8rem + 0.6vw);
  color: ${({ theme }) => theme.text};
  font-family: "Celias";
  line-height: 1.5;
`

export default P
